import { IPassRepository } from "../../boundary/PassRepository/IPassRepository";
import {
  createErrorMessage,
  ErrorMessage,
  ErrorMessageBus,
  IMessageBus,
} from "@roketus/web-toolkit";
import { ERROR_CODE_PASS_FAILED_TO_CREATE } from "../specs/errorCodes";
import { getContainer } from "../../diContainer/container";
import { PositiveMessageEntity } from "../entities/messages/positiveEntity";
import { POSITIVE_PASS_CREATED } from "../specs/positiveCodes";

export const createEmptyPass = async (
  templateId: string,
  passRepository: IPassRepository
): Promise<void | ErrorMessage[]> => {
  const container = getContainer();
  const messageBus = container.getDependency("messageBus") as IMessageBus;

  try {
    await passRepository.createEmpty(templateId);

    const msg: PositiveMessageEntity = {
      type: "positiveEvent",
      message: "",
      source: "createPassService",
      data: POSITIVE_PASS_CREATED,
    };
    messageBus.send(msg);
  } catch (err) {
    const messageBus = container.getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_PASS_FAILED_TO_CREATE,
      source: "createPassUseCase",
      message: "Create Pass Server Error",
      error: err as Error,
    });
    messageBus.send(errorMessage);
  }
};

export const createPassWithBonusQRUseCase = async (
  templateId: string,
  passRepository: IPassRepository
): Promise<void | ErrorMessage[]> => {
  const container = getContainer();
  const messageBus = container.getDependency("messageBus") as IMessageBus;
  try {
    await passRepository.createEmpty(templateId);
    const msg: PositiveMessageEntity = {
      type: "positiveEvent",
      message: "",
      source: "createPassService",
      data: POSITIVE_PASS_CREATED,
    };
    messageBus.send(msg);
  } catch (err) {
    const messageBus = container.getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_PASS_FAILED_TO_CREATE,
      source: "createPassUseCase",
      message: "Create Pass Server Error",
      error: err as Error,
    });
    messageBus.send(errorMessage);
  }
};
