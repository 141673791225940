import { useObservable } from "@roketus/web-toolkit";
import { getContainer } from "../../diContainer/container";
import { IWizardService } from "../../boundary/IWizardService";
import React, { useState } from "react";
import { getSteps } from "../../adapters/services/wizardService/wizardService";

import { isNull } from "lodash";
import { Button } from "@mui/material";
import { PreviewTemplateModal } from "../modals/PreviewTemplateModal";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { RasterPassPreview } from "../RasterPassPreview/RasterPassPreview";

export const WizardHelp = () => {
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });

  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);
  const activeStep = wizardData ? wizardData.activeStep : 0;
  const title = getSteps()[activeStep]?.helpTitle;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <h2>{title}</h2>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        {wizardData && !isNull(wizardData.templateType) && (
          <RasterPassPreview templateID={wizardData.templateType} />
        )}
      </Box>

      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <Button
          sx={{ mt: 2 }}
          onClick={() => setIsModalOpen(true)}
          variant="contained"
        >
          {t("showPreview")}
        </Button>
        <PreviewTemplateModal
          open={isModalOpen}
          templateID={wizardData?.templateType}
          onClose={() => setIsModalOpen(false)}
        />
      </Box>
    </>
  );
};
