import React, { FC } from "react";
import { Box, Button, Modal } from "@mui/material";
import { isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import { RasterPassPreview } from "../RasterPassPreview/RasterPassPreview";

interface props {
  open: boolean;
  templateID?: string;
  onClose: () => void;
}

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "400px",
  background: "#fff",
  padding: "20px",
  borderRadius: "5px",
};

export const PreviewTemplateModal: FC<props> = ({
  open,
  templateID,
  onClose,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "templatesPasses" });
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={containerStyle}
    >
      <Box sx={style}>
        {!isUndefined(templateID) && (
          <RasterPassPreview templateID={templateID} />
        )}

        <Button sx={{ mt: 2 }} onClick={onClose} variant="outlined">
          {t("close")}
        </Button>
      </Box>
    </Modal>
  );
};
