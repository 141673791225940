import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FC } from "react";
import { Layout } from "../Layout";

import { Wizard } from "../Wizard/Wizard";
import { WizardHelp } from "../Wizard/WizardHelp";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const Dashboard: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });
  return (
    <Layout pageTitle={t("dashboardTitle")}>
      <Helmet>
        <title>{t("dashboardTitle")}</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Wizard />
            </Paper>
          </Grid>
          <Grid
            item
            sm={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              },
            }}
          >
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <WizardHelp />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
