import {
  ERROR_CODE_LOGIN_FAILED,
  ERROR_CODE_PASS_REPOSITORY_NETWORK,
  ERROR_CODE_SIGNUP_FAILED,
  ERROR_CODE_UNKNOWN_ERROR,
} from "./domain/specs/errorCodes";
import {
  POSITIVE_CODE_UNKNOWN,
  POSITIVE_TEMPLATE_CREATED,
  BONUS_SUBMITTED,
} from "./domain/specs/positiveCodes";

export const translationsResource = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React",
      errors: {
        [ERROR_CODE_UNKNOWN_ERROR]: "Oops something went wrong!",
        [ERROR_CODE_LOGIN_FAILED]: "Login failed!",
        [ERROR_CODE_SIGNUP_FAILED]: "Sign Up failed!",
        [ERROR_CODE_PASS_REPOSITORY_NETWORK]: "Network Error!",
      },
      success: {
        [POSITIVE_CODE_UNKNOWN]: "Success!",
        [POSITIVE_TEMPLATE_CREATED]: "Template created!",
        [BONUS_SUBMITTED]: "Bonuses added to the card!",
      },
      signInUp: {
        signIn: "Sign In",
        signUp: "Sign Up",
        rememberMe: "Remember me",
        dontHaveAccount: "Don't have an account?",
        username: "Username",
        password: "Password",
        passwordIsRequired: "Password is required",
        confirmPassword: "Confirm Password",
        alreadyHaveAccount: "Already have an account?",
        email: "Email Address",
        emailIsRequired: "Email Address is required",
        usernameIsRequired: "Username is required",
        usernameCanContainDigitsLetters:
          "Username can only contain latin letters and digits",
        notAnEmail: "This is not an Email",
        passwordShouldContain6Letters: "Password should contain 6 letters",
        confirmPasswordRequired: "Confirm Password is required",
        passwordsShouldEqual: "Passwords should be equal",
      },
      templatesPasses: {
        createTemplate: "Create Template",
        createNewTemplate: "Create new Template",
        templates: "Templates",
        basicInfo: "Basic Info",
        organizationName: "Organization Name",
        expirationDate: "Expiration Date",
        orgNameIsRequired: "Orgname is required",
        description: "Description",
        descriptionIsRequired: "Description is required",
        uploadIcon: "Upload Icon",
        iconIsRequired: "Icon is required",
        iconSizeLimitMessage: "Icon must be a .png 174x174px",
        design: "Design",
        uploadThumbnail: "Upload Thumbnail",
        thumbnailSizeLimitMessage: "Thumbnail must be a .png 220x220px",
        uploadBackground: "Upload Background",
        backgroundSizeLimitMessage: "Background must be a .png 640x800px",
        uploadStrip: "Upload Strip",
        stripSizeLimitMessage: "Strip must between 320x123 to 960x369",
        header: "Header",
        headerField: "Header Field",
        logoText: "Logo Text",
        uploadLogo: "Upload Logo",
        logoSizeLimitMessage: "Logo must be a .png 160x50px",
        addField: "Add Field",
        body: "Body",
        addPrimary: "Add Primary Field",
        primaryLabel: "Primary Label",
        primaryValue: "Primary Value",
        primaryKey: "Primary Key",
        secondaryField: "Secondary Field",
        addSecondaryField: "Add Secondary Field",
        label: "Label",
        value: "Value",
        key: "Key",
        alignment: "Alignment",
        left: "left",
        right: "right",
        center: "center",
        natural: "natural",
        auxiliary: "Auxiliary",
        auxiliaryField: "Auxiliary field",
        addAuxiliaryField: "Add auxiliary field",
        barcode: "Barcode",
        useBarcode: "use barcode",
        format: "Format",
        barcodeFormatRequired: "barcode format is required", //translate
        encoding: "Encoding",
        barcodeEncodingRequired: "barcode encoding is required", //translate
        message: "Message",
        barcodeMessageRequired: "barcode message is required", //translate
        altText: "Alt Text",
        back: "Back",
        backField: "Back Field",
        addBackField: "Add Back Field",
        location: "Location",
        addLocation: "Add Location",
        locationName: "Location Name",
        latitude: "Latitude",
        longitude: "Longitude",
        relevantText: "Relevant Text",
        foreground: "Foreground",
        background: "Background",
        logo: "Logo",
        headerFields: "Header Fields",
        primary: "Primary",
        secondary: "Secondary",
        createDefault: "Create Default",
        createWithQR: "Create With QR",
        setAsDefaultTemplate: "Set as Default Template",
        updateAllPasses: "Update All Passes",
        front: "Front",
        close: "Close",
        details: "Details",
      },
      profile: {
        profile: "Profile",
        issuerSystemName: "Issuer System Name",
        apiKey: "Api Key",
        refresh: "Refresh",
        reserveBonusAmount: "Reserve Bonus Amount",
        logOut: "Log Out",
        set: "set",
      },
      posTerminal: {
        posTerminal: "POS Terminal",
        cardNumber: "Card Number",
        submit: "Submit",
        cardMenu: "Card Menu",
        currentBalance: "Current Balance",
        points: "points",
        addPoints: "add Points",
        addPointsToCard: "Add Points to Card",
        add: "Add",
        simulatePurchase: "Simulate Purchase",
        updateUsername: "Update Username",
        updateNameOnCard: "Update Name on Card",
        firstName: "First Name",
        lastName: "Last Name",
        update: "Update",
        pointsAmount: "Points Amount",
      },
      analytics: {
        today: "Today",
        lastWeek: "Last Week",
        lastMonth: "Last Month",
        last60Days: "Last 60 Days",
        last90Days: "Last 90 Days",
        lastYear: "Last Year",
        passesIssued: "Passes Issued",
        numberOfScans: "Number of Scans",
        pointsReceived: "Points Received",
        pointsUsed: "Points Used",
        passesDeleted: "Passes Deleted",
        activeUsers: "Active Users",
        conversionFunnel: "Conversion Funnel",
        scan: "Scan",
        referral: "Referral",
        userRetentionMonthly: "User Retention Monthly",
        usedCumulative: "Used Cumulative",
        analytics: "Analytics",
        email: "Email",
        message: "Message",
        onBoarding: "On-Boarding",
        signUpStart: "Sign-up Start",
        cardsInstalled: "Cards Installed",
        cardsActivated: "Cards Activated",
        firstTransaction: "First Transaction",
        secondTransaction: "Second Transaction",
      },
      menu: {
        dashboard: "Dashboard",
        passes: "Passes",
        templates: "Templates",
        analytics: "Analytics",
        sendNotifications: "Send Notifications",
        integrations: "Integrations",
        profile: "Profile",
        posTerminal: "POS Terminal",
      },
      wizard: {
        wizardTitle: "Let's Begin",
        selectTemplate: "Select Template for Pass",
        templatePreview: "Template Preview",
        continue: "Continue",
        createPass: "Create Pass",
        back: "Back",
        downloadCard: "Download Card",
        downloadIosCard: "iOS",
        downloadAndroidCard: "Android",
        cardInstalledToDevice: "Card Installed to Device",
        bonusMessageReceived: "Bonus Message Received",
        messageReceived: "Message Received",
        send: "Send",
        finish: "Finish",
        pleaseScanQrCode: "Please Scan QR Code",
        createOwnCard: "Create Own Card",
        installCardToYourSmartphone: "Install Card to you Smartphone",
        welcomeBonus: "Welcome Bonus",
        testMessage: "Test Message",
        proceedToGetMoreInformation: "Proceed to get More Information",
        passPreview: "Pass Preview",
        dashboardTitle: "Try Loyalty by Atomic",
        sendTestMessageToYourDevice: "Send test Message to your device",
        testMessageContent: "Let's Rock",
        showPreview: "Show Preview",
      },
      welcomeMessage: "Welcome to Loyalty Dashboard",
    },
  },
  uk: {
    translation: {
      errors: {
        [ERROR_CODE_UNKNOWN_ERROR]: "Упс щось пішло не так!",
        [ERROR_CODE_LOGIN_FAILED]: "Невдалось залогінитись!",
        [ERROR_CODE_SIGNUP_FAILED]: "Невдалось зареєструватись!",
        [ERROR_CODE_PASS_REPOSITORY_NETWORK]: "Помилка мережі!",
      },
      success: {
        [POSITIVE_CODE_UNKNOWN]: "Успіх!",
        [POSITIVE_TEMPLATE_CREATED]: "Шаблон створено!",
        [BONUS_SUBMITTED]: "Бонуси додано на карту!",
      },
      signInUp: {
        signIn: "Увійти",
        signUp: "Зареєструватися",
        rememberMe: "Запам'ятайте мене",
        dontHaveAccount: "Немає облікового запису?",
        username: "Ім'я користувача",
        password: "Пароль",
        passwordIsRequired: "Потрібно ввести пароль",
        confirmPassword: "Підтвердьте пароль",
        alreadyHaveAccount: "Вже є аккаунт?",
        email: "Адреса електронної пошти",
        usernameIsRequired: "Потрібно ввести ім'я користувача",
        usernameCanContainDigitsLetters:
          "Username can only contain latin letters and digits",
        emailIsRequired: "Потрібно ввести email",
        notAnEmail: "Це не схоже на електронну пошту",
        passwordShouldContain6Letters: "Пароль має містити не менше 6 символів",
        confirmPasswordRequired: "Потрібно підтвердити пароль",
        passwordsShouldEqual: "Паролі мають співпадати",
      },
      templatesPasses: {
        createTemplate: "Створити шаблон",
        createNewTemplate: "Створити новий шаблон",
        templates: "Шаблони",
        basicInfo: "Основна інформація",
        organizationName: "Назва організації",
        expirationDate: "Expiration Date",
        orgNameIsRequired: "Необхідно вказати orgName",
        description: "Опис",
        descriptionIsRequired: "Потрібен опис",
        uploadIcon: "Завантажити іконку",
        iconIsRequired: "потрібна іконка",
        iconSizeLimitMessage: "Іконка має бути у форматі .png 174x174 пікселів",
        design: "Дизайн",
        uploadThumbnail: "Завантажити мініатюру",
        thumbnailSizeLimitMessage:
          "Мініатюра має бути у форматі .png 220x220 пікселів",
        uploadBackground: "Завантажити фон",
        backgroundSizeLimitMessage: "Фон має бути .png 640x800 пікселів",
        uploadStrip: "Завантажити стрічку",
        stripSizeLimitMessage: "Стрічка має бути від 320x123 до 960x369",
        header: "Заголовок",
        headerField: "Заголовок",
        logoText: "Текст логотипу",
        uploadLogo: "Завантажити логотип",
        logoSizeLimitMessage: "Логотип має бути у форматі .png 160x50 пікселів",
        addField: "Додати поле",
        body: "Основна частина",
        addPrimary: "Додати основне поле",
        primaryLabel: "Мітка",
        primaryValue: "Значення",
        primaryKey: "Ключ",
        secondaryField: " Другорядне поле",
        addSecondaryField: "Додати другорядне поле",
        label: "Мітка",
        value: "Значення",
        key: "Ключ",
        alignment: "Вирівнювання",
        left: "Ліворуч",
        right: "Праворуч",
        center: "По центру",
        natural: "За замовченням",
        auxiliary: "Допоміжні поля",
        auxiliaryField: "Допоміжні полe",
        addAuxiliaryField: "Додати Допоміжні полe",
        barcode: "Штрих-код",
        useBarcode: "Використовуати штрих-код",
        format: "Формат",
        barcodeFormatRequired: "Необхідний формат штрих-коду",
        encoding: "Кодування",
        barcodeEncodingRequired: "Потрібне кодування штрих-коду",
        message: "Повідомлення",
        barcodeMessageRequired: "Потрібно ввести повідомлення для штрих-коду",
        altText: "Альтернативний текст",
        back: "Зворотня сторона",
        backField: "Зворотна сторона",
        addBackField: "Додати зворотню сторону",
        location: "Місцезнаходження",
        addLocation: "Додати місцезнаходження",
        locationName: "Назва місцезнаходження",
        latitude: "Широта",
        longitude: "Довгота",
        relevantText: "Текст, пов'язаний з локацією",
        foreground: "Передній план",
        background: "Фон",
        logo: "Логотип",
        headerFields: "Поля заголовка",
        primary: "Первинні",
        secondary: "Вторинні",
        createDefault: "Створити картку",
        createWithQR: "Створити картку з QR",
        setAsDefaultTemplate: "Встановити як шаблон за замовчуванням",
        updateAllPasses: "Оновити всі картки",
        front: "Вид спереду",
        close: "Закрити",
        details: "Подробиці",
      },
      profile: {
        profile: "Профіль",
        issuerSystemName: "Системна назва емітента",
        apiKey: "Api ключ",
        refresh: "Оновити",
        reserveBonusAmount: "Ліміт бонусів",
        logOut: "Вийти",
        set: "Встановити",
      },
      posTerminal: {
        posTerminal: "Термінал",
        cardNumber: "Номер картки",
        submit: "Надіслати",
        cardMenu: "Меню картки",
        currentBalance: "Поточний баланс",
        points: "балів",
        addPoints: "Додати бонуси",
        addPointsToCard: "Додати бали до картки",
        add: "Додати",
        simulatePurchase: "Імітація покупки",
        updateUsername: "Оновити ім'я користувача",
        updateNameOnCard: "Оновити ім'я користувача на картці",
        firstName: "Ім'я",
        lastName: "Прізвище",
        update: "Оновити",
        pointsAmount: "Кількість балів",
      },
      analytics: {
        today: "Сьогодні",
        lastWeek: "Минулого тижня",
        lastMonth: "Минулого місяця",
        last60Days: "Останні 60 днів",
        last90Days: "Останні 90 днів",
        lastYear: "Минулого року",
        passesIssued: "Видані картки",
        numberOfScans: "Кількість сканувань",
        pointsReceived: "Отримані бали",
        pointsUsed: "Використані бали",
        passesDeleted: "Видалені картки",
        activeUsers: "Активні користувачі",
        conversionFunnel: "Воронка конверсії",
        scan: "Скан",
        referral: "Реферали",
        userRetentionMonthly: "Щомісячне утримання",
        usedCumulative: "Використано всього",
        analytics: "Аналітика",
        email: "Email",
        message: "Повідомлення",
        onBoarding: "Запрошення",
        signUpStart: "Початок реєстрації",
        cardsInstalled: "Картку встановлено",
        cardsActivated: "Картку активовано",
        firstTransaction: "Перша транзакція",
        secondTransaction: "Друга транзакція",
      },
      menu: {
        dashboard: "Головна",
        passes: "Картки",
        templates: "Шаблони",
        analytics: "Аналітика",
        sendNotifications: "Надсилати сповіщення",
        integrations: "Інтеграція",
        profile: "Профіль",
        posTerminal: "Термінал",
      },
      wizard: {
        wizardTitle: "Почнемо!",
        selectTemplate: "Виберіть шаблон картки",
        templatePreview: "Попередній перегляд шаблону",
        continue: "Далі",
        createPass: "Створити картку",
        back: "Назад",
        downloadCard: "Завантажити картку",
        downloadIosCard: "iOS",
        downloadAndroidCard: "Android",
        cardInstalledToDevice: "Картка встановлена на пристрій",
        bonusMessageReceived: "Бонусне повідомлення отримано",
        messageReceived: "Повідомлення отримано",
        send: "Надіслати",
        finish: "Кінець",
        pleaseScanQrCode: "Відскануйте QR-код",
        createOwnCard: "Створіть власну картку",
        installCardToYourSmartphone: "Встановіть картку на свій смартфон",
        welcomeBonus: "Вітальний бонус",
        testMessage: "Тестове повідомлення",
        proceedToGetMoreInformation:
          "Перейдіть до отримання додаткової інформації",
        passPreview: "Попередній перегляд картки",
        dashboardTitle: "Спробуй лояльність від Atomic",
        sendTestMessageToYourDevice:
          "Надіслати тестове повідомлення на свій пристрій",
        testMessageContent: "Незабаром великий розпродаж!",
        showPreview: "Показати",
      },
      welcomeMessage: "Ласкаво просимо до головної сторінки сервісу лояльності",
    },
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React",
      errors: {
        [ERROR_CODE_LOGIN_FAILED]: "Échec de la connexion!",
      },
      success: {
        [POSITIVE_CODE_UNKNOWN]: "TRANSLATEME!",
        [POSITIVE_TEMPLATE_CREATED]: "TRANSLATEME!",
      },
    },
  },
};
