import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";
import { useTranslation } from "react-i18next";
import { AN_DEMO_FINISH_CLICK } from "../../../domain/specs/userAnalyticsCode";
import { IMessageBus } from "@roketus/web-toolkit";

export const GetMoreInfoStep = () => {
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });

  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const handleFinish = () => {
    wizardService.proceedToNextStep();

    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    messageBus.send({
      type: "GAanalyticsEvent",
      message: AN_DEMO_FINISH_CLICK,
      source: "wizardService",
    });
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={handleFinish}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("finish")}
          </Button>
          <Button
            onClick={wizardService.handlePreviousStep}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("back")}
          </Button>
        </div>
      </Box>
    </>
  );
};
