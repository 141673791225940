import { Alert, Snackbar as MUISnackbar } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { getContainer } from "../diContainer/container";
import { useTranslation } from "react-i18next";
import { ERROR_CODE_UNKNOWN_ERROR } from "../domain/specs/errorCodes";
import { useObservable } from "@roketus/web-toolkit";
import {
  INotifyUserService,
  NotificationType,
} from "../boundary/INotifyUserService";

const AUTO_HIDE_DURATION = 6000;

export const Snackbar = () => {
  const [open, setOpen] = useState(false);
  const [messageCode, setMessageCode] = useState(ERROR_CODE_UNKNOWN_ERROR);
  const [messageType, setMessageType] = useState<NotificationType>("error");
  const { t } = useTranslation();

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const notifyUserService = getContainer().getDependency(
    "notifyUserService"
  ) as INotifyUserService;

  useObservable(notifyUserService.stream$, ({ message, type }) => {
    setMessageCode(message);
    setMessageType(type);
    setOpen(true);
  });

  const translationPrefix = messageType === "error" ? "errors" : "success";
  const fallbackMessage =
    messageType === "error" ? `Oops something went wrong!` : `Success!`;

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={messageType}
        sx={{ width: "100%" }}
      >
        {t(`${translationPrefix}.${messageCode}`, fallbackMessage)}
      </Alert>
    </MUISnackbar>
  );
};
