import { getContainer } from "../../../diContainer/container";

import { IMessageBus, StateSubject } from "@roketus/web-toolkit";
import { IListTemplateService } from "../../../boundary/IListTemplateService";
import {
  IWizardData,
  IWizardService,
  WizardStep,
  WizardStepIndex,
} from "../../../boundary/IWizardService";
import { PositiveMessageEntity } from "../../../domain/entities/messages/positiveEntity";
import {
  CHECK_DEVICE_NOTIFICATION,
  TEMPLATE_CHOSEN,
} from "../../../domain/specs/positiveCodes";
import { ChooseTemplateStep } from "../../../components/Wizard/Steps/1ChooseTemplateStep";
import { InstallCardPhoneStep } from "../../../components/Wizard/Steps/3InstallCardPhoneStep";
import { CheckWelcomeBonusStep } from "../../../components/Wizard/Steps/4CheckWelcomeBonusStep";
import { SendTestMessageStep } from "../../../components/Wizard/Steps/5SendTestMessageStep";
import { GetMoreInfoStep } from "../../../components/Wizard/Steps/6GetMoreInfoStep";
import { sdk, ISDK } from "@roketus/loyalty-js-sdk";
import {
  ANDROID_CARD_DOWNLOAD,
  GA_USER_ID,
  IOS_CARD_DOWNLOAD,
} from "../../../domain/specs/urlParams";
import { ICardService } from "../../../boundary/ICardService";
import { isUndefined } from "lodash";
import { ITemplateRepository } from "../../../boundary/TemplateRepository/ITemplateRepository";
import { Ii18n } from "../../../boundary/Ii18n";
import {
  AN_DEMO_BONUS_RECEIVED,
  AN_DEMO_CARD_CREATED,
  AN_DEMO_CARD_INSTALLED,
  AN_DEMO_CARD_TRY_CREATE,
  AN_DEMO_MSG_RECEIVED,
  AN_DEMO_MSG_SEND,
} from "../../../domain/specs/userAnalyticsCode";
import { IUserAnalyticsService } from "../../../boundary/IUserAnalyticsService";
import { IFeatureToggleService } from "../../../boundary/IFeatureToggleService";

const issuer = process.env.REACT_APP_ISSUER;

export const getSteps = (): WizardStep[] => {
  const i18n = getContainer().getDependency("i18n") as Ii18n;

  return [
    {
      label: i18n.t("wizard.selectTemplate"),
      component: ChooseTemplateStep,
      successMessageData: TEMPLATE_CHOSEN,
      helpTitle: i18n.t("wizard.createOwnCard"),
    },
    {
      label: i18n.t("wizard.installCardToYourSmartphone"),
      component: InstallCardPhoneStep,
      helpTitle: i18n.t("wizard.passPreview"),
    },
    {
      label: i18n.t("wizard.welcomeBonus"),
      component: CheckWelcomeBonusStep,
      helpTitle: i18n.t("wizard.passPreview"),
    },
    {
      label: i18n.t("wizard.testMessage"),
      component: SendTestMessageStep,
      helpTitle: i18n.t("wizard.passPreview"),
    },
    {
      label: i18n.t("wizard.proceedToGetMoreInformation"),
      component: GetMoreInfoStep,
      helpTitle: i18n.t("wizard.passPreview"),
    },
  ];
};

export const init = (messageBus: IMessageBus): IWizardService => {
  const initialState: IWizardData = {
    isLoaded: true,
    loading: false,
    failed: false,
    activeStep: 0,
    downloadIOSCardUrl: "",
    downloadAndroidCardUrl: "",
    cardNumber: "",
    downloadCardUrl: "",
    templateType: "",
    template: null,
    loadingTemplatePreview: false,
  };

  const stateMachine = new StateSubject<IWizardData>(initialState);

  const stepActionMap: Record<WizardStepIndex, any> = {
    0: async () => {
      await setUpTemplate();
      await createCard();
      console.log("action for 2 step: Create Card");
    },
    1: () => {
      console.log("action for 3 step: Card Installed Clicked");
      const messageBus = getContainer().getDependency(
        "messageBus"
      ) as IMessageBus;
      messageBus.send({
        type: "GAanalyticsEvent",
        message: AN_DEMO_CARD_INSTALLED,
        source: "wizardService",
      });
      sendWelcomeBonus();
    },
    2: () => {
      const messageBus = getContainer().getDependency(
        "messageBus"
      ) as IMessageBus;
      messageBus.send({
        type: "GAanalyticsEvent",
        message: AN_DEMO_BONUS_RECEIVED,
        source: "wizardService",
      });
      console.log("action for 4 step: Bonus Received Clicked");
    },
    3: async () => {
      console.log("action for 5 step: Test Message Received Clicked");
      messageBus.send({
        type: "GAanalyticsEvent",
        message: AN_DEMO_MSG_RECEIVED,
        source: "wizardService",
      });
    },
    4: () => {
      console.log("action for last step: finish");
    },
  };

  const setUpTemplate = async () => {
    const listTemplateService = getContainer().getDependency(
      "listTemplateService"
    ) as IListTemplateService;

    const selectedTemplate: string = stateMachine.state$.value.templateType;

    stateMachine.setState({
      loading: true,
    });

    await listTemplateService.setDefaultTemplate(selectedTemplate.toString());
    stateMachine.setState({
      loading: false,
    });
  };

  const createCard = async () => {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    const cardService = getContainer().getDependency(
      "cardService"
    ) as ICardService;

    const featureToggleService = getContainer().getDependency(
      "featureToggleService"
    ) as IFeatureToggleService;
    messageBus.send({
      type: "GAanalyticsEvent",
      message: AN_DEMO_CARD_TRY_CREATE,
      source: "wizardService",
    });

    stateMachine.setState({ loading: true });
    const container = getContainer();

    const loyaltySDK = container.getDependency("loyaltySDK") as ISDK;
    const userAnalyticsService = container.getDependency(
      "userAnalyticsService"
    ) as IUserAnalyticsService;

    if (isUndefined(issuer)) {
      throw new Error("Issuer Not Set in Env");
    }

    loyaltySDK.user.setIssuer(issuer);
    const data = await loyaltySDK.user.card.createAnonymousUserCard();
    const params = new URLSearchParams({
      issuer: process.env.REACT_APP_ISSUER || "",
      [GA_USER_ID]: userAnalyticsService.getUserID(),
      jwt: data.cardJwt,
    });

    const androidUrl = `${
      process.env.REACT_APP_PWA_SIGNIN_URL
    }?${params.toString()}`;

    const cardUrlParams = new URLSearchParams({
      [IOS_CARD_DOWNLOAD]: data.iosCardDownloadLink,
      [ANDROID_CARD_DOWNLOAD]: androidUrl,
      [GA_USER_ID]: userAnalyticsService.getUserID(),
    });

    const cardUrl = `${
      process.env.REACT_APP_BASE_URL
    }download-card/?${cardUrlParams.toString()}`;

    const apiKey = await cardService.getApiKey();
    const jwt = await cardService.getJWTByApiKey(apiKey);
    sdk.user.setUserToken(jwt);

    const short_url = await loyaltySDK.shortLink.createLink({
      name: `card link: ${userAnalyticsService.getUserID()}`,
      linkType: "site",
      url: cardUrl,
      meta: {},
      params: {},
    });

    messageBus.send({
      type: "GAanalyticsEvent",
      message: AN_DEMO_CARD_CREATED,
      source: "wizardService",
    });

    stateMachine.setState({
      loading: false,
      downloadIOSCardUrl: data.iosCardDownloadLink,
      downloadAndroidCardUrl: androidUrl,
      downloadCardUrl: short_url.data.data[0].attributes.url,
      cardNumber: data.cardNumber,
    });

    await sdk.issuer.setSettings({
      templateId: parseInt(
        featureToggleService.data$.value.getFeatureByName<string>(
          "pwaTemplateID"
        ) || "",
        10
      ),
    });
  };

  const changeStep = (toStepId: WizardStepIndex) => {
    stateMachine.setState({
      activeStep: toStepId,
    });
  };

  const sendTestMessage = async ({ message }: { message: string }) => {
    stateMachine.setState({
      loading: true,
    });
    const cardService = getContainer().getDependency(
      "cardService"
    ) as ICardService;

    const loyaltySDK = getContainer().getDependency("loyaltySDK") as ISDK;

    const cardNumber = stateMachine.state$.value.cardNumber;

    try {
      const apiKey = await cardService.getApiKey();
      const jwt = await loyaltySDK.auth.getUserTokenByIssuerAPIKey(apiKey);
      sdk.user.setUserToken(jwt);

      await loyaltySDK.issuer.sendNotificationToCards({
        cardNumbers: [cardNumber],
        message,
      });

      const msg: PositiveMessageEntity = {
        data: CHECK_DEVICE_NOTIFICATION,
        message: "",
        source: "wizardService",
        type: "positiveEvent",
      };
      messageBus.send(msg);
      messageBus.send({
        type: "GAanalyticsEvent",
        message: AN_DEMO_MSG_SEND,
        source: "wizardService",
      });
    } catch (e) {
    } finally {
      stateMachine.setState({
        loading: false,
      });
    }
  };

  const proceedToNextStep = async () => {
    const currentStep: WizardStepIndex = stateMachine.state$.value.activeStep;

    await stepActionMap[currentStep]();

    if (!isUndefined(getSteps()[currentStep].successMessageData)) {
      const msg: PositiveMessageEntity = {
        data: getSteps()[currentStep].successMessageData as string,
        message: "",
        source: "wizardService",
        type: "positiveEvent",
      };
      messageBus.send(msg);
    }

    changeStep((currentStep + 1) as WizardStepIndex);
  };

  const handlePreviousStep = () => {
    const currentStep = stateMachine.state$.value.activeStep;
    changeStep((currentStep - 1) as WizardStepIndex);
  };

  const sendWelcomeBonus = async () => {
    const cardService = getContainer().getDependency(
      "cardService"
    ) as ICardService;

    stateMachine.setState({
      loading: true,
    });

    try {
      await cardService.submitBonus(stateMachine.state$.value.cardNumber, "5");

      const msg: PositiveMessageEntity = {
        data: CHECK_DEVICE_NOTIFICATION,
        message: "",
        source: "wizardService",
        type: "positiveEvent",
      };
      messageBus.send(msg);
    } catch (e) {
    } finally {
      stateMachine.setState({
        loading: false,
      });
    }
  };

  const selectTemplateType = (templateType: string) => {
    stateMachine.setState({
      templateType,
    });
  };

  const loadTemplatePreview = async (templateId: string) => {
    const templateRepository = getContainer().getDependency(
      "templateRepository"
    ) as ITemplateRepository;

    stateMachine.setState({ loadingTemplatePreview: true });

    try {
      const template = await templateRepository.getById(templateId);
      stateMachine.setState({ loadingTemplatePreview: false, template });
    } catch (e) {
      stateMachine.setState({ loadingTemplatePreview: false, template: null });
    }
  };

  return {
    proceedToNextStep,
    handlePreviousStep,
    sendWelcomeBonus,
    selectTemplateType,
    sendTestMessage,
    loadTemplatePreview,
    setDefaultTemplateID(templateId: string) {
      stateMachine.setState({ templateType: templateId });
    },
    data$: stateMachine.state$,
  };
};
