import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { DatePeriodButtonsProps } from "../../boundary/AnalyticsService/DatePeriosButtons";
import { useTranslation } from "react-i18next";

export const DatePeriodButtons = ({
  dateRange,
  handleDateRangeChange,
}: DatePeriodButtonsProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "analytics" });

  return (
    <ToggleButtonGroup
      value={dateRange}
      onChange={handleDateRangeChange}
      exclusive
    >
      <ToggleButton value={1}>{t("today")}</ToggleButton>
      <ToggleButton value={7}>{t("lastWeek")}</ToggleButton>
      <ToggleButton value={30}>{t("lastMonth")}</ToggleButton>
      <ToggleButton value={60}>{t("last60Days")}</ToggleButton>
      <ToggleButton value={90}>{t("last90Days")}</ToggleButton>
      <ToggleButton value={365}>{t("lastYear")}</ToggleButton>
    </ToggleButtonGroup>
  );
};
