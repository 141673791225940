import { FC } from "react";
import { getContainer } from "../../diContainer/container";
import { Ii18n } from "../../boundary/Ii18n";
import Link from "@mui/material/Link";
import { Box, Grid } from "@mui/material";
import styles from "./LanguageSelector.module.css";

export const LanguageSelector: FC = () => {
  const i18n = getContainer().getDependency("i18n") as Ii18n;
  const isCurrentUK = i18n.getCurrentLanguage() === "uk";
  const isCurrentEN = i18n.getCurrentLanguage() === "en";

  return (
    <Box className={styles.wrapper}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {isCurrentEN && (
          <Grid item>
            <Link
              href="#"
              onClick={() => i18n.switchTo("uk")}
              color="secondary"
            >
              Українська
            </Link>
          </Grid>
        )}
        {isCurrentUK && (
          <Grid item>
            <Link
              href="#"
              onClick={() => i18n.switchTo("en")}
              color="secondary"
            >
              English
            </Link>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
