import {
  ExistingPassEntity,
  GenericPassEntity,
} from "../../../domain/entities/GenericPassEntity/GenericPassEntity";
import { CreateTemplateDTO } from "../../../boundary/TemplateRepository/TemplateRepositoryDTO";
import { isUndefined } from "lodash";

export type MediaPayload = {
  icon: {
    base64: string;
    imageId: string;
  };
  logo?: {
    base64: string;
    imageId: string;
  };
};

export const generatePassBarcodeDTO = (pass: GenericPassEntity) => {
  return !pass.barcode?.UseBarcode
    ? { UseBarcode: false }
    : {
        BarcodeAltText: pass.barcode?.BarcodeAltText,
        BarcodeFormatId: Number(pass.barcode?.BarcodeFormatId),
        BarcodeMessage: pass.barcode?.BarcodeMessage,
        MessageEncodingId: Number(pass.barcode?.MessageEncodingId),
        UseBarcode: pass.barcode?.UseBarcode,
      };
};

export const generatePassHeaderDTO = (pass: GenericPassEntity) => {
  return pass.header.headerFields.map((field) => ({
    ChangeMessage: "%@",
    TypeId: 1,
    AlignmentId: parseInt(field.alignment, 10),
    Key: field.key,
    Label: field.label,
    Value: field.value,
    PassFieldId: field.id,
  }));
};

export const generatePassPrimaryDTO = (pass: GenericPassEntity) => {
  if (isUndefined(pass.body.primaryFields)) return [];

  const { key, value, label } = pass.body.primaryFields;
  if (key && value && label) {
    return [
      {
        AlignmentId: 1,
        Key: key,
        Label: label,
        Value: value,
        ChangeMessage: "%@",
        TypeId: 1,
      },
    ];
  } else {
    return [];
  }
};

export const generatePassSecondaryDTO = (pass: GenericPassEntity) => {
  return pass.body.secondaryFields.map((field) => ({
    ChangeMessage: "%@",
    TypeId: 1,
    AlignmentId: parseInt(field.alignment, 10),
    Key: field.key,
    Label: field.label,
    Value: field.value,
    PassFieldId: field.id,
  }));
};

export const generatePassAuxiliaryDTO = (pass: GenericPassEntity) => {
  return pass.body.auxiliaryFields.map((field) => ({
    ChangeMessage: "%@",
    TypeId: 1,
    AlignmentId: parseInt(field.alignment, 10),
    Key: field.key,
    Label: field.label,
    Value: field.value,
    PassFieldId: field.id,
  }));
};

export const generatePassBackDTO = (pass: GenericPassEntity) => {
  return pass.body.passBack.map((field) => ({
    ChangeMessage: "%@",
    TypeId: 1,
    Key: field.key,
    Label: field.label,
    Value: field.value,
    PassBackId: field.id,
  }));
};

export const generatePassLocationDTO = (pass: GenericPassEntity) => {
  if (!pass.location) return [];

  return pass.location.map((field) => ({
    Latitude: parseFloat(field.Latitude),
    Longitude: parseFloat(field.Longitude),
    RelevantText: field.RelevantText || "",
    LocationName: field.LocationName || "",
  }));
};

export const convertToCreateTemplateDTO = (
  pass: GenericPassEntity
): CreateTemplateDTO => {
  return {
    CreatedDate: new Date().toISOString(),
    PassAuxiliary: generatePassAuxiliaryDTO(pass),
    PassBack: generatePassBackDTO(pass),
    PassBarcode: generatePassBarcodeDTO(pass),
    PassBeacon: [],
    PassHeader: generatePassHeaderDTO(pass),
    PassInfo: {
      AppLauncher: null,
      AuthenticationToken: null,
      CertificateId: null,
      CompanionApps: null,
      Description: pass.description ?? "",
      ExpirationDate: pass.expirationDate || null,
      GroupingIdentifier: null,
      IgnoreTimezone: false,
      LogoText: pass.header.logoText || null,
      NonFree: false,
      OrganizationName: pass.orgName,
      PassTypeIdentifier: null,
      PreviewImgId: 0,
      RelevantDate: null,
      SerialNumber: null,
      StyleId: 4,
      TeamIdentifier: null,
      VoidPass: false,
      WebServiceURL: null,
      iTunesIdentifier: null,
    },
    PassLocation: generatePassLocationDTO(pass),
    PassPrimary: generatePassPrimaryDTO(pass),
    PassSecondary: generatePassSecondaryDTO(pass),
    PassStyle: {
      IconUrl: pass.iconId,
      LogoUrl: pass.header.logoId ?? null,
      Background: pass.design.backgroundColor,
      BackgroundUrl: pass.design.backgroundId,
      FooterUrl: null,
      Foreground: pass.design.foregroundColor,
      ImageStyleId: "1",
      Label: pass.design.labelColor,
      StripUrl: pass.design.stripId,
      TransitType: "PKTransitTypeAir",
      ThumbnailUrl: pass.design.thumbnailId,
    },
    Preview: "",
  };
};

export const convertToUpdateTemplateDTO = (
  pass: ExistingPassEntity
): CreateTemplateDTO => {
  const convertedTemplate = convertToCreateTemplateDTO(
    pass as GenericPassEntity
  );
  convertedTemplate.PassInfo.PassInfoId = parseInt(pass.id, 10);
  return convertedTemplate;
};
