import {
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useObservable } from "@roketus/web-toolkit";
import { getContainer } from "../../diContainer/container";
import { IWizardService } from "../../boundary/IWizardService";
import { IFeatureToggleService } from "../../boundary/IFeatureToggleService";
import React from "react";
import { getSteps } from "../../adapters/services/wizardService/wizardService";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector";
import Typography from "@mui/material/Typography";

export const Wizard = () => {
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });

  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);

  const featureToggleService = getContainer().getDependency(
    "featureToggleService"
  ) as IFeatureToggleService;

  const featureData = useObservable(featureToggleService.data$);

  const isLoading =
    (wizardData && wizardData.loading) || (featureData && featureData.loading);

  return (
    <>
      <Typography variant="h3">{t("wizardTitle")}</Typography>

      {wizardData && (
        <>
          {isLoading && <CircularProgress />}
          <Stepper activeStep={wizardData.activeStep} orientation="vertical">
            {getSteps().map(({ label, component }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>{React.createElement(component)}</StepContent>
              </Step>
            ))}
          </Stepper>
        </>
      )}
      <LanguageSelector />
    </>
  );
};
