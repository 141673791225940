import { IMessage, IMessageBus } from "@roketus/web-toolkit";
import {
  AN_DEMO_BONUS_RECEIVED,
  AN_DEMO_CARD_CHOSEN,
  AN_DEMO_CARD_CREATED,
  AN_DEMO_CARD_DOWNLOADED,
  AN_DEMO_CARD_DOWNLOADED_BY_QR,
  AN_DEMO_CARD_INSTALLED,
  AN_DEMO_CARD_TRY_CREATE,
  AN_DEMO_FINISH_CLICK,
  AN_DEMO_MSG_RECEIVED,
  AN_DEMO_MSG_SEND,
} from "../domain/specs/userAnalyticsCode";
import { filter } from "rxjs";
import { v4 } from "uuid";
import { IUserAnalyticsService } from "../boundary/IUserAnalyticsService";

const isAnalyticsMessage = (value: IMessage): boolean =>
  [
    AN_DEMO_CARD_CHOSEN,
    AN_DEMO_CARD_TRY_CREATE,
    AN_DEMO_CARD_CREATED,
    AN_DEMO_CARD_DOWNLOADED,
    AN_DEMO_CARD_INSTALLED,
    AN_DEMO_BONUS_RECEIVED,
    AN_DEMO_MSG_SEND,
    AN_DEMO_MSG_RECEIVED,
    AN_DEMO_FINISH_CLICK,
    AN_DEMO_CARD_DOWNLOADED_BY_QR,
  ].includes(value.message);

export const init = (messageBus: IMessageBus): IUserAnalyticsService => {
  let user_id = v4();
  const gtag = window.gtag;
  gtag("set", { user_id });

  messageBus.stream$.pipe(filter(isAnalyticsMessage)).subscribe((message) => {
    const send = (eventName: string, value?: string) => {
      console.log("$$sending", "event", eventName, {
        value: value,
      });

      console.log("$$sending uid", user_id);

      gtag("event", eventName, {
        value: value,
      });
    };

    if (message.message === AN_DEMO_CARD_CHOSEN) {
      return send(AN_DEMO_CARD_CHOSEN, message.data as string);
    }

    return send(message.message);
  });

  return {
    setUserID(userId: string) {
      gtag("set", { user_id: userId });
      user_id = userId;
    },
    getUserID() {
      return user_id;
    },
  };
};
