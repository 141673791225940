import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";
import { useObservable } from "@roketus/web-toolkit";
import { useTranslation } from "react-i18next";

export const CheckWelcomeBonusStep = () => {
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });

  const wizardService = getContainer().getDependency(
      "wizardService"
  ) as IWizardService;
  const data$ = useObservable(wizardService.data$);

  return (
      <>
        <Box sx={{ mb: 2 }}>
          <div>
            <Button
                variant="contained"
                disabled={data$?.loading}
                onClick={wizardService.proceedToNextStep}
                sx={{ mt: 1, mr: 1 }}
            >
              {t("bonusMessageReceived")}
            </Button>
            <Button
                onClick={wizardService.handlePreviousStep}
                sx={{ mt: 1, mr: 1 }}
            >
              {t("back")}
            </Button>
          </div>
        </Box>
      </>
  );
};
