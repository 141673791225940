import { GenericPassEntity } from "../../../domain/entities/GenericPassEntity/GenericPassEntity";
import { IPassRepository } from "../../../boundary/PassRepository/IPassRepository";
import { getContainer } from "../../../diContainer/container";
import { ClientResponse, HTTPClient } from "../../../boundary/HTTPClient";
import { createErrorMessage, ErrorMessageBus } from "@roketus/web-toolkit";
import {
  CreatePassDTO,
  CreatePassResponseDTO,
} from "../../../boundary/PassRepository/PassRepositoryDTO";
import { convertToCreatePassDTO } from "../../data/GenericPassEntity/convertToCreatePassDTO";
import {
  ERROR_CODE_PASS_REPOSITORY_VALIDATION,
  ERROR_CODE_UNKNOWN_ERROR,
} from "../../../domain/specs/errorCodes";
import { convertPassListResponseDTOToPassEntity } from "../../data/PassListResponseDTO/convertToPassEntity";
import { validateTemplateListDTO } from "../../../boundary/TemplateRepository/TemplateListDTO.validator";

export const create = (pass: GenericPassEntity): Promise<void> => {
  const client = getContainer().getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  const payload: CreatePassDTO = convertToCreatePassDTO(pass);

  return client
    .post("/api/v2/pass", payload)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

const list = async ({ page = 0 }) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  const data = await client.get("api/pass", { params: { page } });

  try {
    const validData = validateTemplateListDTO(data.data);
    return convertPassListResponseDTOToPassEntity(validData);
  } catch (e) {
    const messageBus = container.getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_PASS_REPOSITORY_VALIDATION,
      source: "passRepository",
      message: "PassListResponseDTO not valid",
      error: e as Error,
    });
    messageBus.send(errorMessage);

    return {
      passes: [],
      pagingData: {
        page: 0,
        pageSize: 0,
        totalData: 0,
        totalPages: 0,
      },
    };
  }
};

const createEmpty: IPassRepository["createEmpty"] = async (templateId) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;
  const errorMessageBus = container.getDependency(
    "errorMessageBus"
  ) as ErrorMessageBus;

  const payload: ClientResponse<CreatePassResponseDTO> = await client.post(
    "/api/v2/pass",
    {
      TemplateId: templateId,
    }
  );

  try {
    return {
      id: payload.data.data.PassInfoId,
      serialNumber: payload.data.data.SerialNumber,
    };
  } catch (e) {
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_UNKNOWN_ERROR,
      source: "passRepository",
      message: "Failed Parse Response",
      error: e as Error,
    });
    errorMessageBus.send(errorMessage);
  }
};

const download = async (passId: string) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  client({
    url: `/api/passFile/${passId}`, //your url
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${passId}.pkpass`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

const deletePass = async (passId: string): Promise<void> => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  await client({
    url: `api/pass/${passId}`,
    method: "DELETE",
  });
};

export const updateQrCode: IPassRepository["updateQrCode"] = async (
  passId,
  qrCodeData
) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  await client({
    url: `api/fullPassInfo`,
    method: "PUT",
    data: {
      PassInfo: {
        PassInfoId: parseInt(passId, 10),
      },
      PassBarcode: {
        UseBarcode: true,
        BarcodeMessage: qrCodeData,
        BarcodeAltText: qrCodeData,
      },
    },
  });
};

export const passRepository: IPassRepository = {
  download,
  create,
  list,
  createEmpty,
  delete: deletePass,
  updateQrCode,
};
