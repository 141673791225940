import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import { NavLink } from "./NavLink/NavLink";
import { useTranslation } from "react-i18next";

export const MainListItems = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <NavLink to="/dashboard">
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.dashboard")} />
        </ListItemButton>
      </NavLink>

      <NavLink to="/analytics">
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary={t("menu.analytics")} />
        </ListItemButton>
      </NavLink>
    </React.Fragment>
  );
};
