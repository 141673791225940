import { IMessageBus, LoadableData, StateSubject } from "@roketus/web-toolkit";
import { sdk } from "@roketus/loyalty-js-sdk";
import { IBalanceInfo, ICardService } from "../../boundary/ICardService";
import { getContainer } from "../../diContainer/container";
import { IProfileService } from "../../boundary/ProfileService/ProfileService";
import { filter, lastValueFrom, take } from "rxjs";
import { isEmpty, last } from "lodash";
import { PositiveMessageEntity } from "../../domain/entities/messages/positiveEntity";
import { BONUS_SUBMITTED } from "../../domain/specs/positiveCodes";

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const init = (messageBus: IMessageBus): ICardService => {
  const stateMachine = new StateSubject<LoadableData & IBalanceInfo>({
    loading: false,
    isLoaded: true,
    failed: false,
    currentBalance: 0,
  });

  const getJWTByApiKey = async (apiKey: string) => {
    return await sdk.auth.getUserTokenByIssuerAPIKey(apiKey);
  };

  const getApiKey = async () => {
    const container = getContainer();

    const profileService = container.getDependency(
      "profileService"
    ) as IProfileService;

    profileService.fetchProfile();
    const updateApiKeysubscription$ = profileService.state$.pipe(
      filter((data) => !isEmpty(data.profile.ApiKey)),
      take(1)
    );
    const data = await lastValueFrom(updateApiKeysubscription$);
    return data.profile.ApiKey;
  };

  const reservePoints = async (amount: string) => {
    await sdk.user.card.addReserve({ amount: parseInt(amount, 10) });
  };

  const fetchBalance = async () => {
    try {
      stateMachine.setState({ loading: true });

      const response = await sdk.user.card.getBalanceHistory();
      const lastHistoryRecord = last(response.data.data);
      const currentBalance = lastHistoryRecord?.attributes.Balance;
      stateMachine.setState({
        currentBalance: currentBalance ?? 0,
        isLoaded: true,
        loading: false,
      });
    } catch (e) {
      stateMachine.setState({ failed: true, isLoaded: true, loading: false });
    }
  };

  const submitBonus = async (cardNumber: string, bonus: string) => {
    stateMachine.setState({ loading: true, isLoaded: false });

    const apiKey = await getApiKey();
    const jwt = await getJWTByApiKey(apiKey);
    sdk.user.setUserToken(jwt);

    await sdk.user.card.addPoints({
      cardNumber: cardNumber,
      description: "present from Santa",
      amount: parseInt(bonus, 10),
    });

    stateMachine.setState({ loading: false, isLoaded: true });
    const msg: PositiveMessageEntity = {
      data: BONUS_SUBMITTED,
      message: "",
      source: "bonusService",
      type: "positiveEvent",
    };
    messageBus.send(msg);
  };

  const simulatePurchase = async (cardNumber: string) => {
    await submitBonus(cardNumber, "4");
    await sleep(6000);
    await fetchBalance();
  };

  const sendNotificationByCard = async ({
    cardNumber,
    message,
  }: {
    cardNumber: string;
    message: string;
  }) => {
    stateMachine.setState({ loading: true });

    await sdk.issuer.sendNotificationToCards({
      cardNumbers: [cardNumber],
      message: message,
    });
    stateMachine.setState({ loading: false });
  };

  return {
    data$: stateMachine.state$,
    getJWTByApiKey,
    getApiKey,
    reservePoints,
    fetchBalance,
    updateUsername: async (
      cardNumber: string,
      name: { firstName: string; lastName: string }
    ) => {
      const { firstName, lastName } = name;

      stateMachine.setState({ loading: true, isLoaded: false });

      await sdk.user.card.updateCard({
        cardNumber,
        data: {
          first_name: firstName,
          last_name: lastName,
        },
      });
      stateMachine.setState({ loading: false, isLoaded: true });
      // const msg: PositiveMessageEntity = {
      //   data: BONUS_SUBMITTED,
      //   message: "",
      //   source: "bonusService",
      //   type: "positiveEvent",
      // };
      // messageBus.send(msg);
    },
    submitBonus,
    simulatePurchase,
    sendNotificationByCard,
  };
};
