import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";
import { useObservable } from "@roketus/web-toolkit";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const SendTestMessageStep = () => {
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });

  const [message, setMessage] = useState<string>(t("testMessageContent"));
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);

  const onSendClicked = async () => {
    await wizardService.sendTestMessage({ message });
    setMessage("");
    setIsMessageSent(true);
  };

  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;
  const data$ = useObservable(wizardService.data$);

  return (
    <>
      <Typography>{t("sendTestMessageToYourDevice")}</Typography>
      <TextField
        value={message}
        autoFocus
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            disabled={data$?.loading}
            onClick={onSendClicked}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("send")}
          </Button>
          <Button
            variant="contained"
            disabled={data$?.loading || !isMessageSent}
            onClick={wizardService.proceedToNextStep}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("messageReceived")}
          </Button>
          <Button
            onClick={wizardService.handlePreviousStep}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("back")}
          </Button>
        </div>
      </Box>
    </>
  );
};
