import { initContainer } from "./container";
import { IHTTPClientInstance, persistentStorage } from "@roketus/web-toolkit";
import { authService } from "../adapters/services/authService";
import { MessageBus, ErrorMessageBus } from "@roketus/web-toolkit";
import { client } from "../adapters/clients/publicClients";
import { client as publicLoyaltyClient } from "../adapters/clients/publicLoyaltyClient";
import { init as initPrivateClient } from "../adapters/clients/privatePassServerClients";
import { init as listPassServiceInit } from "../adapters/services/ListPassesService";
import { init as listTemplateServiceInit } from "../adapters/services/ListTemplatesService";
import { profileService } from "../adapters/services/profileService";
import { init as initFormData } from "../adapters/services/messageBus/streams/formData";
import { init as initCreatePassService } from "../adapters/services/createPassService";
import { init as initCreateTemplateService } from "../adapters/services/createTemplateService";
import { init as initWizardService } from "../adapters/services/wizardService/wizardService";
import { passRepository } from "../adapters/repositories/passRepository/passRepository";
import { templateRepository } from "../adapters/repositories/templateRepository/templateRepository";
import { init as initNotifyUser } from "../adapters/services/notifyUserService";
import { signUpService } from "../adapters/services/signUpService/signUpService";
import { ImageRepository } from "../adapters/repositories/ImageRepository/ImageRepository";
import { init as initCardService } from "../adapters/services/cardService";
import { init as initPassNotificationService } from "../adapters/services/PassNotificationService";
import { init as initAnalyticsService } from "../adapters/services/analyticsService";
import { init as initI18n } from "../adapters/services/i18nService";
import { initSdk } from "../adapters/loyaltySDKInitializer";
import { init as initUserAnalyticsService } from "../adapters/userAnalyticsService";
import { init as initFeatureToggleService } from "../adapters/services/FeatureToggleService";
import { init as initSetupService } from "../adapters/services/SetupServiceData";

export const init = function (): void {
  const messageBus = new MessageBus();
  const formData = initFormData(messageBus);
  const i18n = initI18n(persistentStorage);
  const sdk = initSdk(messageBus);
  const errorMessageBus = new ErrorMessageBus(messageBus);
  const featureToggleService = initFeatureToggleService(
    errorMessageBus,
    publicLoyaltyClient as IHTTPClientInstance
  );

  const wizardService = initWizardService(messageBus);

  initContainer({
    persistentStorage,
    i18n,
    authService,
    messageBus,
    errorMessageBus,
    publicClient: client,
    publicLoyaltyClient,
    privatePassServerClients: initPrivateClient(messageBus),
    passRepository,
    templateRepository,
    listPassService: listPassServiceInit(passRepository),
    listTemplateService: listTemplateServiceInit(templateRepository),
    profileService,
    formData,
    createPassService: initCreatePassService(formData, passRepository),
    createTemplateService: initCreateTemplateService(formData),
    notifyUserService: initNotifyUser(messageBus),
    signUpService,
    imageRepository: new ImageRepository(messageBus),
    cardService: initCardService(messageBus),
    passNotificationService: initPassNotificationService(),
    analyticsService: initAnalyticsService(),
    wizardService,
    loyaltySDK: sdk,
    userAnalyticsService: initUserAnalyticsService(messageBus),
    featureToggleService,
    setupService: initSetupService({
      authService,
      ftService: featureToggleService,
      wizardService,
      messageBus,
    }),
  });

  authService.init(featureToggleService);
};
