import { FC, useEffect, useRef } from "react";
import { Layout } from "../Layout";
import { Container } from "@mui/material";
import { getContainer } from "../../diContainer/container";
import {
  createErrorMessage,
  ErrorMessageBus,
  IMessageBus,
} from "@roketus/web-toolkit";
import Typography from "@mui/material/Typography";
import { isOS } from "../../adapters/OS/isOS";
import { useQuery } from "../../adapters/hooks/useQuery";
import {
  ANDROID_CARD_DOWNLOAD,
  IOS_CARD_DOWNLOAD,
} from "../../domain/specs/urlParams";
import { AN_DEMO_CARD_DOWNLOADED_BY_QR } from "../../domain/specs/userAnalyticsCode";
import { AutoUserIDSetter } from "../AutoUserIDSetter";
import { isNil } from "lodash";

export const DownloadCard: FC = () => {
  let query = useQuery();
  const isDownloading = useRef(false);
  const iOSUrl = query.get(IOS_CARD_DOWNLOAD);
  const androidUrl = query.get(ANDROID_CARD_DOWNLOAD);

  const errorMessageBus = getContainer().getDependency(
    "errorMessageBus"
  ) as ErrorMessageBus;

  const isValidParams = !isNil(iOSUrl) && !isNil(androidUrl);

  useEffect(() => {
    const download = async () => {
      try {
        if (!isValidParams) return;

        if (isDownloading.current) return;

        const isIOSSystem = isOS();
        if (isIOSSystem) {
          window.location.href = iOSUrl;
        } else {
          window.open(androidUrl, "_blank");
        }

        const messageBus = getContainer().getDependency(
          "messageBus"
        ) as IMessageBus;
        messageBus.send({
          type: "GAanalyticsEvent",
          message: AN_DEMO_CARD_DOWNLOADED_BY_QR,
          source: "wizardService",
        });
        isDownloading.current = true;
      } catch (e) {
        const msg = createErrorMessage({
          message: "Failed to download card",
          error: e as Error,
          source: "DownloadCard",
        });
        errorMessageBus.send(msg);
      }
    };
    download();
  }, [errorMessageBus, iOSUrl, androidUrl, isValidParams]);

  return isValidParams ? (
    <AutoUserIDSetter>
      <Layout pageTitle={`Card Download`}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h2">Downloading your card</Typography>
          <Typography variant="h2">please wait...</Typography>
        </Container>
      </Layout>
    </AutoUserIDSetter>
  ) : null;
};
