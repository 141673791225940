export const AN_DEMO_CARD_CHOSEN = "demo_card_chosen";
export const AN_DEMO_CARD_TRY_CREATE = "demo_card_try_create";
export const AN_DEMO_CARD_CREATED = "demo_card_created";
export const AN_DEMO_CARD_DOWNLOADED = "demo_card_downloaded";
export const AN_DEMO_CARD_DOWNLOADED_BY_QR = "demo_card_downloaded_by_qr";
export const AN_DEMO_CARD_INSTALLED = "demo_card_installed";
export const AN_DEMO_BONUS_RECEIVED = "demo_bonus_received";
export const AN_DEMO_MSG_SEND = "demo_msg_send";
export const AN_DEMO_MSG_RECEIVED = "demo_msg_received";
export const AN_DEMO_FINISH_CLICK = "demo_finish_click";
