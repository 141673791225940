import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";
import bwipjs from "bwip-js";
import { isNull } from "lodash";
import { IMessageBus, useObservable } from "@roketus/web-toolkit";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { AN_DEMO_CARD_DOWNLOADED } from "../../../domain/specs/userAnalyticsCode";
import { Android, Apple } from "@mui/icons-material";

export const InstallCardPhoneStep = () => {
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });

  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;
  const data$ = useObservable(wizardService.data$);
  const drawBarcode = (el: HTMLCanvasElement) => {
    bwipjs.toCanvas(el, {
      bcid: "qrcode",
      text: data$?.downloadCardUrl || "e",
    });
  };

  const sendDownloadEvent = () => {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    messageBus.send({
      type: "GAanalyticsEvent",
      message: AN_DEMO_CARD_DOWNLOADED,
      source: "wizardService",
    });
  };
  return (
    <>
      <Typography>{t("pleaseScanQrCode")}</Typography>

      <canvas
        ref={(el) => {
          if (isNull(el)) return;
          drawBarcode(el);
        }}
        id="barcode"
      ></canvas>

      <Box sx={{ mb: 2 }}>
        <div>
          <Link
            href={data$?.downloadIOSCardUrl}
            target="_blank"
            underline="none"
            onClick={sendDownloadEvent}
          >
            <Button
              startIcon={<Apple />}
              variant="outlined"
              sx={{ mt: 1, mr: 1 }}
              color="secondary"
              about={t("downloadIosCard")}
            >
              {t("downloadIosCard")}
            </Button>
          </Link>
          <Link
            href={data$?.downloadAndroidCardUrl}
            target="_blank"
            underline="none"
            onClick={sendDownloadEvent}
          >
            <Button
              startIcon={<Android />}
              variant="outlined"
              sx={{ mt: 1, mr: 1 }}
              color="secondary"
              about={t("downloadAndroidCard")}
            >
              {t("downloadAndroidCard")}
            </Button>
          </Link>
        </div>

        <div>
          <Button
            variant="contained"
            onClick={wizardService.proceedToNextStep}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("cardInstalledToDevice")}
          </Button>
          <Button
            onClick={wizardService.handlePreviousStep}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("back")}
          </Button>
        </div>
      </Box>
    </>
  );
};
