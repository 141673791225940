import i18n from "i18next";
import { Ii18n, SupportedLangCodes } from "../../boundary/Ii18n";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { PersistentStorage } from "@roketus/web-toolkit/dist/cjs/interfaces/PersistentStorage";
import { translationsResource } from "../../dictionary";

const LANG_KEY = "i18nextLng";
const DEFAULT_LANG = "en";

export const init = (persistentStorage: PersistentStorage): Ii18n => {
  const languageDetector = new LanguageDetector();
  languageDetector.addDetector({
    name: "customDetector",
    lookup() {
      return persistentStorage.getItem(LANG_KEY) || DEFAULT_LANG;
    },
    cacheUserLanguage(lng) {
      persistentStorage.setItem(LANG_KEY, lng);
    },
  });

  i18n.use(initReactI18next).use(languageDetector).init({
    resources: translationsResource,
    fallbackLng: DEFAULT_LANG,
  });

  return {
    t: i18n.t,
    switchTo(code: SupportedLangCodes) {
      i18n.changeLanguage(code);
    },
    getCurrentLanguage(): SupportedLangCodes {
      return i18n.resolvedLanguage as SupportedLangCodes;
    },
  };
};
