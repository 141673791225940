import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";
import {
  IFeatureToggleService,
  ITemplateFeature,
} from "../../../boundary/IFeatureToggleService";
import React, { useEffect, useState } from "react";
import { IMessageBus, useObservable } from "@roketus/web-toolkit";
import { isNull, isUndefined } from "lodash";
import { WizardHelp } from "../WizardHelp";
import { useTranslation } from "react-i18next";
import { AN_DEMO_CARD_CHOSEN } from "../../../domain/specs/userAnalyticsCode";

export const ChooseTemplateStep = () => {
  const [templateType, setTemplateType] = useState("");
  const { t } = useTranslation("translation", { keyPrefix: "wizard" });

  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);

  const featureToggleService = getContainer().getDependency(
    "featureToggleService"
  ) as IFeatureToggleService;

  const featureData = useObservable(featureToggleService.data$);
  const features = featureData?.getFeatureByName<ITemplateFeature>("templates");
  const defaultTemplateType =
    !isUndefined(features) && features.length
      ? features[0].id
      : wizardData?.templateType;

  useEffect(() => {
    if (!isUndefined(defaultTemplateType)) {
      wizardService.loadTemplatePreview(defaultTemplateType);
      setTemplateType(defaultTemplateType);
    }
  }, [defaultTemplateType, wizardService]);

  const onTemplateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const templateId = event.target.value;
    wizardService.selectTemplateType(templateId);
    wizardService.loadTemplatePreview(templateId);
    setTemplateType(templateId);
  };

  return (
    <>
      <FormControl>
        {!isNull(wizardData) && (
          <RadioGroup value={templateType} onChange={onTemplateChanged}>
            {features?.map(({ id, name = "" }) => (
              <FormControlLabel
                key={id}
                value={id}
                control={<Radio />}
                label={name}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
          },
        }}
      >
        <WizardHelp />
      </Box>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            disabled={!isNull(wizardData) && wizardData.loading}
            variant="contained"
            onClick={() => {
              wizardService.proceedToNextStep();
              const messageBus = getContainer().getDependency(
                "messageBus"
              ) as IMessageBus;
              messageBus.send({
                type: "GAanalyticsEvent",
                message: AN_DEMO_CARD_CHOSEN,
                source: "wizardService",
                data: templateType,
              });
            }}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("createPass")}
          </Button>
        </div>
      </Box>
    </>
  );
};
