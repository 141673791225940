import {
  INotifyUserService,
  NotificationType,
} from "../../boundary/INotifyUserService";
import { IMessageBus, isErrorMessage } from "@roketus/web-toolkit";
import { filter, map, merge } from "rxjs";
import {
  ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE,
  ERROR_CODE_PASS_ENTITY_ICON_REQUIRED,
  ERROR_CODE_PASS_ENTITY_ICON_SIZE,
  ERROR_CODE_PASS_ENTITY_ICON_TYPE,
  ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED,
  ERROR_CODE_SIGNUP_FAILED,
} from "../../domain/specs/errorCodes";
import {
  isPositiveMessageEntity,
  PositiveMessageEntity,
} from "../../domain/entities/messages/positiveEntity";
import { IErrorMessage } from "../../boundary/IMessage";
import {
  POSITIVE_SIGNUP,
  POSITIVE_TEMPLATE_CREATED,
  BONUS_SUBMITTED,
  TEMPLATE_CHOSEN,
  PASS_CREATED,
  CHECK_DEVICE_NOTIFICATION,
} from "../../domain/specs/positiveCodes";

const SUPPORTED_POSITIVE_MESSAGE_CODES = [
  POSITIVE_TEMPLATE_CREATED,
  POSITIVE_SIGNUP,
  BONUS_SUBMITTED,
  TEMPLATE_CHOSEN,
  PASS_CREATED,
  CHECK_DEVICE_NOTIFICATION,
];
const needToShowPositiveMessageEntity = (m: PositiveMessageEntity) =>
  SUPPORTED_POSITIVE_MESSAGE_CODES.includes(m.data);

export const SUPPORTED_ERROR_CODES = [
  ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED,
  ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE,
  ERROR_CODE_PASS_ENTITY_ICON_REQUIRED,
  ERROR_CODE_PASS_ENTITY_ICON_TYPE,
  ERROR_CODE_PASS_ENTITY_ICON_SIZE,
  ERROR_CODE_SIGNUP_FAILED,
];
const needToShowErrorMessageEntity = (m: IErrorMessage) =>
  SUPPORTED_ERROR_CODES.includes(m.data?.code);

export const init = (messageBus: IMessageBus): INotifyUserService => {
  const success$ = messageBus.stream$.pipe(
    filter(isPositiveMessageEntity),
    filter(needToShowPositiveMessageEntity),
    map((v) => {
      const result: {
        message: string;
        type: NotificationType;
      } = {
        message: v.data,
        type: "success",
      };

      return result;
    })
  );

  const error$ = messageBus.stream$.pipe(
    filter(isErrorMessage),
    filter(needToShowErrorMessageEntity),
    map((v) => {
      const result: {
        message: string;
        type: NotificationType;
      } = {
        message: v.data.code,
        type: "error",
      };

      return result;
    })
  );

  return {
    stream$: merge(success$, error$),
  };
};
